<template>
  <div>
    <div class="product-detail-item-mobile">
      <dandelion-navigation-mobile />
      <img class="product-image" :src="default_cover" />
      <div class="price-container">
        <div class="size-text" :style="{ visibility: has_attributes ? 'visible' : 'hidden' }">
          <img
            v-if="Object.keys(scanned_combination.attributes).includes('shade')"
            class="shade-icon"
            :src="scanned_combination.attributes.shade.value"
          />{{ Object.values(scanned_combination.attributes)[0].name }}
        </div>
        <div class="discount-price-text" :class="{ 'text-dark': !has_discount }">
          Rp {{ scanned_combination.price_after_discount | currency }}
        </div>
        <div class="full-price-text" :style="{ visibility: !has_discount ? 'hidden' : 'visible' }">
          Rp {{ scanned_combination.price | currency }}
        </div>
      </div>
      <div class="info-container">
        <div class="brand-name">{{ data.brand.name }}</div>
        <div class="product-name">{{ data.name }}</div>
        <div class="rating--uniform timeline-review">
          <div
            class="rate-star--uniform"
            :class="'rate-star--uniform-' + $options.filters.rating(data.review_stats.average_rating)"
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <span class="average-review"> {{ data.review_stats.average_rating.toFixed(1) }}</span>
          <span class="total-review"
            >({{ data.review_stats.total_reviews ? data.review_stats.total_reviews : '0' }})</span
          >
        </div>
        <div v-if="bpom_reg_no.length > 0" class="bpom-text">BPOM Reg. No {{ bpom_reg_no }}</div>
        <div v-if="other_size_variant.length > 0" class="other-variant-container">
          Tersedia dalam variant lain:
          <div class="variant-text">
            <div v-for="variant in other_size_variant" :key="variant.id">
              <span class="variant-size-text">{{ variant.attributes.size.name }}</span>
              <span class="variant-discount-price">Rp {{ variant.price_after_discount | currency }} </span>
              <span v-if="variant.price !== variant.price_after_discount" class="variant-full-price"
                >Rp {{ variant.price | currency }}</span
              >
            </div>
          </div>
        </div>
        <div v-else-if="has_other_type_variant" class="other-variant-container">Tersedia dalam variant lain.</div>
      </div>
      <div v-if="bundles && bundles.length > 0" class="bundle-container">
        <div class="bundle-title">Tersedia dalam bundle!</div>
        <div class="bundle-slider">
          <div v-for="item in bundles" :key="item.id" class="bundle-item">
            <figure>
              <img
                :src="
                  item.default_combination
                    ? item.default_combination.images.find((x) => x.is_cover).url
                    : item.combinations[0].images.find((x) => x.is_cover).url
                "
              />
            </figure>
            <div class="bundle-content">
              <div class="bundle-name">{{ item.name.length > 20 ? item.name.slice(0, 20) + '...' : item.name }}</div>
              <div class="bundle-discount-price">
                Rp
                {{
                  (item.default_combination
                    ? item.default_combination.price_after_discount
                    : item.combinations[0].price_after_discount) | currency
                }}<span
                  v-if="
                    item.default_combination
                      ? item.default_combination.price !== item.default_combination.price_after_discount
                      : item.combinations[0].price !== item.combinations[0].price_after_discount
                  "
                  class="bundle-full-price"
                  >Rp
                  {{
                    (item.default_combination ? item.default_combination.price : item.combinations.price) | currency
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="product-detail-item-desktop">
      <div class="desktop-product-info-container">
        <div class="product-image">
          <img class="logo" src="/static/img/dandelion-store/sociollaX-logo.png" alt="sociolla X" />
          <img class="image" :src="default_cover" />
        </div>
        <div class="price-container">
          <div class="size-text" :style="{ visibility: has_attributes ? 'visible' : 'hidden' }">
            <img
              v-if="Object.keys(scanned_combination.attributes).includes('shade')"
              class="shade-icon"
              :src="scanned_combination.attributes.shade.value"
            />{{ Object.values(scanned_combination.attributes)[0].name }}
          </div>
          <div class="discount-price-text" :class="{ 'text-dark': !has_discount }">
            Rp {{ scanned_combination.price_after_discount | currency }}
          </div>
          <div class="full-price-text" :style="{ visibility: !has_discount ? 'hidden' : 'visible' }">
            Rp {{ scanned_combination.price | currency }}
          </div>
        </div>
        <div class="info-container">
          <div class="brand-name">{{ data.brand.name }}</div>
          <div class="product-name">{{ data.name }}</div>
          <div class="rating--uniform timeline-review">
            <div
              class="rate-star--uniform"
              :class="'rate-star--uniform-' + $options.filters.rating(data.review_stats.average_rating)"
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <span class="average-review"> {{ data.review_stats.average_rating.toFixed(1) }}</span>
            <span class="total-review"
              >({{ data.review_stats.total_reviews ? data.review_stats.total_reviews : '0' }})</span
            >
          </div>
          <div v-if="bpom_reg_no.length > 0" class="bpom-text">BPOM Reg. No {{ bpom_reg_no }}</div>
          <div v-if="other_size_variant.length > 0" class="other-variant-container">
            Tersedia dalam variant lain:
            <div class="variant-text">
              <div v-for="variant in other_size_variant" :key="variant.id">
                <span class="variant-size-text">{{ variant.attributes.size.name }}</span>
                <span class="variant-discount-price">Rp {{ variant.price_after_discount | currency }} </span>
                <span v-if="variant.price !== variant.price_after_discount" class="variant-full-price"
                  >Rp {{ variant.price | currency }}</span
                >
              </div>
            </div>
          </div>
          <div v-else-if="has_other_type_variant" class="other-variant-container">Tersedia dalam variant lain.</div>
        </div>
      </div>
      <div class="banner-bundle-container">
        <div v-if="bundles && bundles.length > 0" class="bundle-container">
          <div class="bundle-slider">
            <div class="bundle-title">Tersedia dalam bundle!</div>
            <div v-for="item in bundles" :key="item.id" class="bundle-item">
              <figure>
                <img
                  :src="
                    item.default_combination
                      ? item.default_combination.images.find((x) => x.is_cover).url
                      : item.combinations[0].images.find((x) => x.is_cover).url
                  "
                />
              </figure>
              <div class="bundle-content">
                <div class="bundle-name">{{ item.name.length > 30 ? item.name.slice(0, 30) + '...' : item.name }}</div>
                <div class="bundle-discount-price">
                  Rp
                  {{
                    (item.default_combination
                      ? item.default_combination.price_after_discount
                      : item.combinations[0].price_after_discount) | currency
                  }}<span
                    v-if="
                      item.default_combination
                        ? item.default_combination.price !== item.default_combination.price_after_discount
                        : item.combinations[0].price !== item.combinations[0].price_after_discount
                    "
                    class="bundle-full-price"
                    >Rp
                    {{
                      (item.default_combination ? item.default_combination.price : item.combinations.price) | currency
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="banner_url" class="banner" :style="{ 'margin-top': bundles && bundles.length > 0 ? '20px' : '0' }">
          <img :src="banner_url" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DandelionNavigationMobile from './dandelion-navigation-mobile.vue';
export default {
  components: {
    DandelionNavigationMobile,
  },
  props: {
    data: {
      required: true,
      default: () => {},
      type: Object,
    },
  },
  computed: {
    bundles() {
      return this.$store.state.dandelionStore.bundle_product;
    },
    banner_url() {
      return this.$store.state.dandelionStore.banners?.pdp;
    },
    bpom_reg_no() {
      if (this.scanned_combination.bpom_reg_no && this.scanned_combination.bpom_reg_no.length > 0) {
        return this.scanned_combination.bpom_reg_no;
      } else if (this.data.bpom_reg_no && this.data.bpom_reg_no.length > 0) {
        return this.data.bpom_reg_no;
      } else {
        return '';
      }
    },
    has_other_type_variant() {
      return (
        this.data.combinations.filter((x) => {
          return (
            (!Object.keys(x.attributes).includes('size') || Object.keys(x.attributes).length > 1) &&
            !Object.keys(x.attributes).includes('non_specify') &&
            Object.keys(x.attributes)[0].name !== Object.values(this.scanned_combination.attributes)[0].name &&
            x.is_active_in_offline_store
          );
        }).length > 0
      );
    },
    other_size_variant() {
      return this.data.combinations.filter((x) => {
        return (
          Object.keys(x.attributes).length === 1 &&
          Object.keys(x.attributes).includes('size') &&
          x.attributes.size.name !== Object.values(this.scanned_combination.attributes)[0].name &&
          x.is_active_in_offline_store
        );
      });
    },
    scanned_object_attribute() {
      return Object.values(this.scanned_combination.attributes)[0];
    },
    has_attributes() {
      return !this.scanned_object_attribute.name.toLowerCase().includes('non specify');
    },
    has_discount() {
      return this.scanned_combination.price_after_discount !== this.scanned_combination.price;
    },
    default_cover() {
      const cover = this.scanned_combination.images.find((x) => x.is_cover);
      if (cover && Object.keys(cover).includes('url')) {
        return cover.url;
      } else {
        return '/static/img/dandelion-store/default-product-image.png';
      }
    },
    scanned_combination() {
      return this.data.combinations.find((x) => x.ean_no === this.$route.params.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.rate-star--uniform {
  margin: 13px 10px 13px 0;
  span {
    height: 16px;
    width: 17px;
    background-size: 17px 16px;
    margin-left: 2px;
    &:before {
      height: 16px;
      background-size: 17px 16px;
      background-image: url('/static/img/icons/rating-pink-star.svg');
    }
  }
}

.average-review {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  margin-right: 10px;
}
.total-review {
  font-family: 'Lato-Regular', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: rgba(140, 133, 130, 1);
}

@mixin ratingstar($value) {
  $newvalue: ceil($value / 10);
  $rounddown: floor($value / 10);
  @for $i from 1 to $newvalue + 1 {
    @if ($i == 1) {
      span:nth-child(1):before {
        width: 100% !important;
      }
    } @else {
      @if ($i == $newvalue) {
        @if ($value - ($rounddown * 10) == 0) {
          span:nth-child(#{$i}):before {
            width: 100% !important;
          }
        } @else {
          span:nth-child(#{$i}):before {
            width: percentage(($value - ($rounddown * 10)) / 10) !important;
          }
        }
      } @else {
        span:nth-child(#{$i}):before {
          width: 100% !important;
        }
      }
    }
  }
}

@for $i from 10 to 51 {
  .rate-star--uniform-#{$i} {
    @include ratingstar($i);
  }
  .rs-#{$i} {
    @include ratingstar($i);
  }
}
@media (max-width: 768px) {
  .product-detail-item-desktop {
    display: none;
  }
  .product-detail-item-mobile {
    position: relative;
    font-family: 'Lato-Regular', sans-serif;
    .bundle-container {
      background: #fff;
      border-top: 8px #f8f8f8 solid;
      .bundle-title {
        font-family: 'Lato-Bold', sans-serif;
        color: #231f20;
        margin: 12px 16px;
        font-weight: 700;
      }
      .bundle-item {
        box-shadow: 0px 0px 9px 1px #0000000b;
        padding: 8px;
        min-width: 250px !important;
        display: flex;
        align-items: center;
        gap: 12px;
        figure {
          width: 50px;
          height: 50px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            -o-object-fit: cover;
          }
        }
        .bundle-content {
          width: calc(100% - 60px);
          .bundle-name {
            color: #231f20;
            font-size: 14px;
            font-family: 'Lato-Regular', sans-serif;
          }
          .bundle-discount-price {
            font-family: 'Lato-Bold', sans-serif;
            color: #eb395f;
          }
          .bundle-full-price {
            margin-left: 5px;
            font-family: 'Lato-Regular', sans-serif;
            color: #8c8582;
            text-decoration: line-through;
          }
        }
      }
    }
    .bundle-slider {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      gap: 10px;
      padding: 16px;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    .product-image {
      margin-top: 20px;
      padding: 0 20px;
      width: 100%;
      object-fit: cover;
    }
    .price-container {
      font-family: 'brandontext-regular', sans-serif;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(252, 233, 240, 1);
      padding: 10px;
      text-align: center;
      width: 100%;
      background: rgba(255, 251, 252, 1);
      margin: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5%;
    }
    .size-text {
      border: 1px solid rgba(218, 42, 82, 1);
      color: rgba(218, 42, 82, 1);
      border-radius: 4px;
      padding: 4px 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      .shade-icon {
        align-self: center;
        max-width: 12px;
        max-height: 12px;
        margin-right: 5px;
      }
    }
    .discount-price-text {
      font-size: 24px;
      font-weight: 700;
      line-height: 28.8px;
      color: rgba(218, 42, 82, 1);
    }
    .full-price-text {
      color: rgba(140, 133, 130, 1);
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      text-decoration: line-through;
      align-self: flex-start;
    }
    .text-dark {
      color: #000 !important;
    }
    .info-container {
      padding: 12px 15px;
    }
    .brand-name {
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 2px;
      text-align: left;
      margin-bottom: 8px;
    }
    .product-name {
      font-size: 16px;
      font-weight: 390;
      line-height: 21px;
      text-align: left;
    }
    .bpom-text {
      font-size: 14px;
      font-weight: 330;
      line-height: 18px;
      text-align: left;
      color: #8c8582 !important;
    }
    .other-variant-container {
      background: #fbfbfb;
      margin-top: 24px;
      padding: 12px 16px;
      font-weight: 400;
      text-align: left;
      border-radius: 4px;

      .variant-size-text {
        display: inline-block;
        border: 1px solid #e5e5e5;
        background: #fff;
        width: 60px;
        border-radius: 4px;
        padding: 4px 0;
        text-align: center;
        margin-top: 16px;
      }
      .variant-discount-price {
        font-family: 'lato-bold';
        color: #231f20;
        margin-left: 15px;
      }
      .variant-full-price {
        color: #8c8582;
        text-decoration: line-through;
        margin-left: 5px;
      }
    }
  }
}
@media (min-width: 769px) {
  .banner-bundle-container {
    margin-bottom: auto;
    .bundle-container {
      max-width: 35vw;
    }
    .bundle-title {
      font-family: 'Lato-Bold', sans-serif;
      color: #231f20;
      font-weight: 700;
    }
    .bundle-slider {
      background: #fff;
      border: 1px solid #e5e5e5;
      box-shadow: 0px 10px 10px 0px #9031574d;
      border-radius: 15px;
      padding: 12px 16px;
    }
    .bundle-item {
      border: 1px solid #e5e5e5;
      padding: 8px;
      min-width: 250px !important;
      display: flex;
      align-items: center;
      gap: 12px;
      margin-top: 12px;
      border-radius: 4px;
      figure {
        width: 50px;
        height: 50px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          -o-object-fit: cover;
        }
      }
      .bundle-content {
        width: 100%;
        .bundle-name {
          color: #231f20;
          font-size: 14px;
          font-family: 'Lato-Regular', sans-serif;
        }
        .bundle-discount-price {
          font-family: 'Lato-Bold', sans-serif;
          color: #eb395f;
        }
        .bundle-full-price {
          margin-left: 5px;
          font-family: 'Lato-Regular', sans-serif;
          color: #8c8582;
          text-decoration: line-through;
        }
      }
    }
  }
  .banner {
    max-width: 30vw;
    aspect-ratio: 4/3;
    background: grey;
    border-radius: 15px;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      -o-object-fit: fill;
      border-radius: 15px;
    }
  }
  .product-detail-item-mobile {
    display: none;
  }
  .product-detail-item-desktop {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100vh;
    padding: 40px 0;
    gap: 20px;
    @supports (-ms-ime-align: auto) {
      .desktop-product-info-container {
        margin-right: 20px;
      }
    }
    .desktop-product-info-container {
      max-width: 38vw;
      height: 100%;
      border-radius: 15px;
      padding: 32px 24px;
      background: #fff;
      border: 1px solid #e5e5e5;
    }
    font-family: 'Lato-Regular', sans-serif;
    .product-image {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .logo {
        width: 135px;
      }
      .image {
        padding: 0 20px;
        max-width: 40%;
        margin: 20px auto;
        object-fit: cover;
      }
    }
    .price-container {
      font-family: 'brandontext-regular', sans-serif;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(252, 233, 240, 1);
      padding: 10px;
      text-align: center;
      width: 100%;
      background: rgba(255, 251, 252, 1);
      margin: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }

    @supports (-ms-ime-align: auto) {
      .price-container .size-text {
        margin-right: 10px;
      }
    }
    .size-text {
      border: 1px solid rgba(218, 42, 82, 1);
      color: rgba(218, 42, 82, 1);
      border-radius: 4px;
      padding: 4px 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      .shade-icon {
        align-self: center;
        max-width: 12px;
        max-height: 12px;
        margin-right: 5px;
      }
    }
    .discount-price-text {
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      color: rgba(218, 42, 82, 1);
    }
    .full-price-text {
      color: rgba(140, 133, 130, 1);
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-decoration: line-through;
    }
    .text-dark {
      color: #000 !important;
    }
    .info-container {
      padding: 12px 15px;
    }
    .brand-name {
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 2px;
      text-align: left;
      margin-bottom: 8px;
    }
    .product-name {
      font-size: 16px;
      font-weight: 390;
      line-height: 21px;
      text-align: left;
    }
    .bpom-text {
      font-size: 14px;
      font-weight: 330;
      line-height: 18px;
      text-align: left;
      color: #8c8582 !important;
    }
    .other-variant-container {
      background: #fbfbfb;
      margin-top: 24px;
      padding: 12px 16px;
      font-weight: 400;
      text-align: left;
      border-radius: 4px;

      .variant-size-text {
        display: inline-block;
        border: 1px solid #e5e5e5;
        background: #fff;
        width: 60px;
        border-radius: 4px;
        padding: 4px 0;
        text-align: center;
        margin-top: 16px;
      }
      .variant-discount-price {
        font-family: 'lato-bold';
        color: #231f20;
        margin-left: 15px;
      }
      .variant-full-price {
        color: #8c8582;
        text-decoration: line-through;
        margin-left: 5px;
      }
    }
  }
}
</style>
